import { html } from 'lit-html';
import styles from './checkbox.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-checkbox";

class CheckboxComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return ['color', 'text', 'checked'];
  }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  get text() { return this.getAttribute('text'); }
  set text(value) { this.setAttribute('text', value); }

  get checked() { 
    const checked = this.getAttribute('checked'); 
    return checked === 'true' || checked === true;
  }
  set checked(value) { 
    const checked = value === 'true' || value === true;
    this.setAttribute('checked', checked);
  }

  __clickHandler(){
    this.checked = !this.checked;
    this.dispatchEvent(new CustomEvent('checkboxToggle', { 
      detail: { 
        element: this,
        checked: this.checked 
      },
      bubbles: true,
      composed: true,
    }));
    this.render();
  }

  template() {
    const color = this.color ?? 'primary';
    const text = this.text ?? '';
    const checked = this.checked === 'true' || this.checked === true;
    const iconClass = checked ? "ml-icon-checkbox-ok" : "ml-icon-checkbox_empty";

    return /* template */html`
      <div @click="${(e) => this.__clickHandler()}" class="ml-checked ${color}">
        <i class="icon-1 ${iconClass}"></i>
        <span class="text">${text}</span>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, CheckboxComponent);
