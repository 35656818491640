import { html } from 'lit-html';
import styles from './common-textarea.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-common-textarea";

class CommonTextareaComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__errorMsg = "";
  }

  onInit() { }

  onDestroy() { }

  __inputChangeHandler(event) {
    if (event.target.validity.valid) {
      this.value = event.target.value;
    } else {
      event.target.value = this.value;
    }

    this.dispatchEvent(new CustomEvent('inputChange', {
      detail: this.value,
      bubbles: true,
      composed: true,
    }));
  }

  static get observedAttributes() {
    return ['placeholder', 'value', 'rows', 'color'];
  }

  get placeholder() { return this.getAttribute('placeholder'); }
  set placeholder(value) { this.setAttribute('placeholder', value); }

  get value() { return this.getAttribute('value'); }
  set value(value) { 
    this.setAttribute('value', value); 
    this.root.querySelector('textarea').value = value;
    this.__errorMsg = "";
  }

  get rows() { return this.getAttribute('rows'); }
  set rows(value) { this.setAttribute('rows', value); }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  setError(errorMsg){
    this.__errorMsg = errorMsg;
    this.render();
  }

  template() {
    const errorMsg = this.__errorMsg ?? "";
    const inputClass = errorMsg.length > 0 ? "error" : "";

    return /* template */html`
      <div class="ml-common-textarea ${inputClass}">
        <textarea 
          class="${this.color ?? 'primary'} ${inputClass}"
          @input="${(e) => this.__inputChangeHandler(e)}"
          placeholder="${this.placeholder ?? '-'}"
          rows="${this.rows ?? 1}">${ this.value ?? "" }</textarea>
        <span>${errorMsg}</span>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, CommonTextareaComponent);
