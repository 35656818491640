import { html } from 'lit-html';
import styles from './questions-preview.styles.scss';
import WebComponent from 'utils/web-component';
import "components/atom/selector/selector.component";
import "components/atom/button/button.component";
import "components/atom/icon-button/icon-button.component";
import "components/atom/paginate-selector/paginate-selector.component";
import "components/molecules/question-card/question-card.component";
import "components/atom/confirmation-msg-modal/confirmation-msg.component";
import { STATE } from 'utils/const';
import { QuestionsApi } from 'api_connection';
import authReducer from 'storage/reducers/auth';

const ComponentTagName = "ml-questions-preview-modal";

export const QU_PREVIEW_ACTIONS = {
  APPROVE: 'approve',
  DELETE: 'delete',
  PUBLISH_ALL: 'publish_all',
  APPROVE_ALL: 'approve_all',
  DELETE_ALL: 'delete_all'
}

class QuestionsPreviewModalComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__user = authReducer().user;
    this.handlePaginateSelectorEvent = this.handlePaginateSelectorEvent.bind(this);
  }

  onInit() {
    this.__questions = [];
    this.__currentQuestionIndex = 0;
    this.__showConfirmation = false;
    this.__confirmationState = null;
    this.__totalDraft = 0;
    document.addEventListener('paginate-selector-item-clicked', this.handlePaginateSelectorEvent);
  }

  onDestroy() { 
    document.removeEventListener('paginate-selector-item-clicked', this.handlePaginateSelectorEvent);
  }

  afterCallback(){
    const questionCard = this.root.querySelector(`ml-question-card[index="${this.__currentQuestionIndex}"]`);
    if (questionCard) questionCard.scrollIntoView({ behavior: 'smooth' });
  }

  handlePaginateSelectorEvent(event) {
    this.__currentQuestionIndex = +event.detail.index;
    this.__checkNavigationState();
    this.__checkButtonsActions();

    const questionCard = this.root.querySelector(`ml-question-card[index="${this.__currentQuestionIndex}"]`);
    if(questionCard) questionCard.scrollIntoView({ behavior: 'smooth' });
  }

  static get observedAttributes() {
    return [];
  }

  close(){
    const background = this.root.querySelector('.ml-modal-background');
    const body = this.root.querySelector('.ml-modal-body');
    body.classList.remove('show');
    setTimeout(() => {
      background.classList.add('hidden');
      this.__questions = [];
      this.__totalDraft = 0;
      this.dispatchEvent(new CustomEvent('close-question-preview-modal'));
      this.render();
    }, 400);
  }

  open(data, questionId){
    const background = this.root.querySelector('.ml-modal-background');
    const body = this.root.querySelector('.ml-modal-body');
    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    background.classList.remove('hidden');

    this.__questions = data;
    this.__totalDraft = data.filter(question => question.sts == STATE.DRAFT).length;
    if(questionId){
      const index = this.__questions.findIndex(question => question.qid == questionId);
      if(index >= 0) this.__currentQuestionIndex = index;
      data.forEach((item, i) => item.active = i == index);
    }else{
      this.__currentQuestionIndex = 0;
      data[0].active = true;
    }

    if(paginateSelector) paginateSelector.loadList(data);
    setTimeout(() => {
      body.classList.add('show');
      this.__checkNavigationState();
      this.__checkButtonsActions();
    }, 100);
    this.render();
  }

  updateData(data){
    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    const question = this.__questions[this.__currentQuestionIndex];
    if(question){
      data.forEach((item) => item.active = item.qid == question.qid);
    }else{
      this.__currentQuestionIndex = 0;
      data.forEach((item, i) => item.active = i == 0);
    }
    this.__questions = data;
    if(paginateSelector) paginateSelector.loadList(data);
    this.render();
  }

  __checkNavigationState() {
    const previousButton = this.root.querySelector('#previous-question-button');
    const nextButton = this.root.querySelector('#next-question-button');

    if(this.__currentQuestionIndex <= 0) {
      previousButton.mode = 'disabled';
      nextButton.mode = 'normal';
      return;
    }

    if(this.__currentQuestionIndex >= this.__questions.length - 1) {
      previousButton.mode = 'normal';
      nextButton.mode = 'disabled';
      return;
    }

    previousButton.mode = 'normal';
    nextButton.mode = 'normal';
  }

  __checkButtonsActions(){
    const approveButton = this.root.querySelector('ml-button-v2[color="warning"]');
    const deleteButton = this.root.querySelector('ml-button-v2[color="accent"]');
    const question = this.__questions[this.__currentQuestionIndex];

    if(question && question.sts == STATE.DRAFT){
      approveButton.classList.toggle('hidden', false);
      deleteButton.classList.toggle('hidden', false);
      return;
    }

    if (question && [STATE.STAGING, STATE.REVISION].includes(question.sts)){
      approveButton.classList.toggle('hidden', true);
      deleteButton.classList.toggle('hidden', false);
      return;
    }

    approveButton.classList.toggle('hidden', true);
    deleteButton.classList.toggle('hidden', true);
  }

  __selectorClicked(actionId) {
    const confirmationElement = this.root.querySelector('preview-confirmation-msg');
    const mainContainer = this.root.querySelector('.main-container');
    const selector = this.root.querySelector('ml-selector');

    if (selector) selector.close();

    const toApprove = this.__questions.filter(question => question.sts == STATE.DRAFT).length;
    let content = {
      title: "¿Seguro que quieres seleccionar todas para aprobar?",
      okText: "Sí, aprobar selección",
      htmlContent: `
        <p>
          Al hacerlo, <b>${toApprove} preguntas</b> serán clasificadas para <b>aprobar</b>
        </p>
      `,
    }

    if(actionId == QU_PREVIEW_ACTIONS.DELETE_ALL) {
      const toRemove = this.__questions.filter(question => (question.sts == STATE.DRAFT || question.sts == STATE.STAGING)).length;
      content = {
        title: `¿Seguro que quieres eliminar ${toRemove} preguntas?`,
        okText: "Sí, eliminar",
        htmlContent: `
          <p> Esta acción no se puede deshacer </p>
          <p> Para eliminar las ${toRemove} preguntas escribe ELIMINAR </p>
        `,
      }
    }

    if(actionId == QU_PREVIEW_ACTIONS.PUBLISH_ALL) {
      const toPublish = this.__questions.filter(question => question.sts == STATE.STAGING).length;
      content = {
        title: `¿Seguro que quieres seleccionar todas para publicar?`,
        okText: "Sí, publicar",
        htmlContent: `
          <p>
            Al hacerlo, <b>${toPublish} preguntas</b> serán clasificadas para <b>publicar</b>
          </p>
        `,
      }
    }

    if(confirmationElement) confirmationElement.loadData({
      title: content.title,
      htmlContent: content.htmlContent,
      inputText: actionId == QU_PREVIEW_ACTIONS.DELETE_ALL ? "ELIMINAR" : "",
      okText: content.okText,
      onOk: () => {
        mainContainer.classList.toggle('show-confirmation', false);
        this.__confirmAction(actionId);
        this.close();
      },
      onCancel: () => mainContainer.classList.toggle('show-confirmation', false)
    });

    if (mainContainer) mainContainer.classList.toggle('show-confirmation', true);
  }

  __nextQuestionClicked() {
    this.__currentQuestionIndex += 1;
    this.__checkNavigationState();

    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    const nextQuestionCard = this.root.querySelector(`ml-question-card[index="${this.__currentQuestionIndex}"]`);

    if(nextQuestionCard){
      nextQuestionCard.scrollIntoView({ behavior: 'smooth' });
      if(paginateSelector) paginateSelector.updateIndex(this.__currentQuestionIndex);  
      this.__checkButtonsActions();
    }else{
      this.__currentQuestionIndex -= 1;
    }
  }

  __previousQuestionClicked() {
    this.__currentQuestionIndex -= 1;
    this.__checkNavigationState();

    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    const previousQuestionCard = this.root.querySelector(`ml-question-card[index="${this.__currentQuestionIndex}"]`);

    if(previousQuestionCard){
      previousQuestionCard.scrollIntoView({ behavior: 'smooth' });
      if(paginateSelector) paginateSelector.updateIndex(this.__currentQuestionIndex);
      this.__checkButtonsActions();
    }else{
      this.__currentQuestionIndex += 1;
    }
  }

  __actionClicked(actionId){
    const confirmationElement = this.root.querySelector('preview-confirmation-msg');
    const mainContainer = this.root.querySelector('.main-container');

    let content = {
      title: "¿Estás seguro?",
      okText: "Sí, aprobar",
      htmlContent: `
        <p> Una vez que una pregunta está en aprobado ya no se podrá editar, </p>
        <p> ni salir de este estado, sólo se podrá publicar junto a otra preguntas </p>
        <p> que estén en staging de esta misma base de conocimiento. </p>
      `,
    }

    if (actionId == QU_PREVIEW_ACTIONS.DELETE) content = {
      title: "¿Estás seguro? Esta acción no tiene vuelta atrás",
      okText: "Sí, eliminar",
      htmlContent: `
        <p> La pregunta pasará a el estado "deprecate", </p>
        <p> lista para ser eliminada definitivamente. </p>
      `,
    }

    if (confirmationElement) confirmationElement.loadData({
      title: content.title,
      htmlContent: content.htmlContent,
      inputText: actionId == QU_PREVIEW_ACTIONS.DELETE ? "eliminar" : "",
      okText: content.okText,
      onOk: () => {
        mainContainer.classList.toggle('show-confirmation', false);
        this.__confirmAction(actionId);
      },
      onCancel: () => mainContainer.classList.toggle('show-confirmation', false)
    });

    if (mainContainer) mainContainer.classList.toggle('show-confirmation', true);
  }

  __confirmAction(actionId){
    let currentQuestion = this.__questions[this.__currentQuestionIndex];

    switch(actionId){
      case QU_PREVIEW_ACTIONS.APPROVE:
        QuestionsApi.write.revisionQuestion(
          { pid: this.__user.pid, qidl: [currentQuestion.qid] },
          () => {
            currentQuestion.sts = STATE.REVISION;
            this.render();
          }
        );

        break;
      case QU_PREVIEW_ACTIONS.DELETE:
        const paginateSelector = this.root.querySelector('ml-paginate-selector');
        QuestionsApi.write.deleteQuestion(
          { pid: this.__user.pid, qidl: [currentQuestion.qid] },
          () => {
            this.__questions = this.__questions.filter(question => question.qid !== currentQuestion.qid);
            if(this.__questions.length == 0) return this.close();
            this.__currentQuestionIndex -= 1;
            if(this.__currentQuestionIndex < 0) this.__currentQuestionIndex = 0;
            if (paginateSelector) paginateSelector.loadList(this.__questions.map((item, i) => ({ 
              ...item, 
              active: i == this.__currentQuestionIndex
            })));
            if(currentQuestion.sts == STATE.DRAFT)this.__totalDraft -= 1;
            this.render();
          },
        )
        break;
      case QU_PREVIEW_ACTIONS.PUBLISH_ALL:
        let questionsToPublish = this.__questions.filter(question => (question.sts == STATE.REVISION || question.sts == STATE.STAGING));
        questionsToPublish = questionsToPublish.map(question => question.qid);

        if (questionsToPublish.length) QuestionsApi.write.readyQuestion(
          { pid: this.__user.pid, qidl: questionsToPublish },
          () => this.close(),
        );
        break;
      case QU_PREVIEW_ACTIONS.APPROVE_ALL:
        let questionsToApprove = this.__questions.filter(question => question.sts == STATE.DRAFT);
        questionsToApprove = questionsToApprove.map(question => question.qid);

        if(questionsToApprove.length) QuestionsApi.write.revisionQuestion(
          { pid: this.__user.pid, qidl: questionsToApprove },
          () => this.close(),
        );
        break;
      case QU_PREVIEW_ACTIONS.DELETE_ALL:
        let questionsInDraft = this.__questions.filter(question => question.sts == STATE.DRAFT);
        questionsInDraft = questionsInDraft.map(question => question.qid);

        if(questionsInDraft.length) QuestionsApi.write.deleteQuestion(
          { pid: this.__user.pid, qidl: questionsInDraft },
          () => this.close(),
        )
        break;
    }
  }

  renderCounterModule() {
    const questions = this.__questions ?? [];
    const draftQuantity = questions.filter(question => question.sts == STATE.DRAFT).length;

    return html`
      <div class="counter-card rounded-2xl shadow-ml p-4">
        <span class="main-number font-bold">${draftQuantity}</span>
        <span class="secondary-number font-bold">/${this.__totalDraft}</span>
        <span class="text"> preguntas </span>
      </div>
    `
  }

  renderSelectorModule() {
    const questions = this.__questions ?? [];
    const approveAllClass = questions.some(question => question.sts == STATE.DRAFT) ? '' : 'disabled';
    const deleteAllClass = questions.some(question => (question.sts == STATE.DRAFT || question.sts == STATE.STAGING)) ? '' : 'disabled';
    const publishAllClass = questions.some(question => question.sts == STATE.STAGING) ? '' : 'disabled';

    return html`
      <ml-selector text="Seleccionar todas para">
        <div class="selection-options ${approveAllClass}"
          @click="${(e) => this.__selectorClicked(QU_PREVIEW_ACTIONS.APPROVE_ALL)}">
          <i class="icon-1 ml-icon-checkbox-ok text-warning"></i>
          <p class="text-14">aprobar</p>
        </div>
        <div class="selection-options ${deleteAllClass}"
          @click="${(e) => this.__selectorClicked(QU_PREVIEW_ACTIONS.DELETE_ALL)}">
          <i class="icon-1 ml-icon-checkbox-mal text-accent"></i>
          <p class="text-14">eliminar</p>
        </div>
        <div class="selection-options ${publishAllClass}"
          @click="${(e) => this.__selectorClicked(QU_PREVIEW_ACTIONS.PUBLISH_ALL)}">
          <i class="icon-1 ml-icon-checkbox-ok text-success"></i>
          <p class="text-14">publicar</p>
        </div>
      </ml-selector>
    `
  }

  renderQuestionsCards() {
    this.__questions ??= [];
    if(this.__questions.length === 0) return html``;
    return this.__questions.map((question, index) => {
      return html`
        <ml-question-card 
          .question="${question}" 
          index="${index}" 
          class="question-card-width">
        </ml-question-card>
      `
    });
  }

  template() {
    return /* template */html`
      <div class="ml-modal-background hidden">
        <div class="ml-modal-body">
          <i @click="${(e) => this.close()}" class="ml_close"></i>
          <div class="flex justify-center items-center gap-2">
            <h5 class="title font-bold m-0"> Vista previa </h5>
            <img src="assets/icons/b/pregunta.svg" width="30" alt="question-icon" />
          </div>

          <div class="main-container">
            <div class="confirmation-wrap">
              <preview-confirmation-msg></preview-confirmation-msg>
            </div>

            <div class="content">
              <div class="flex flex-col items-start">
                ${this.renderCounterModule()}
              </div>

              <div class="flex flex-col items-start">
                ${this.renderSelectorModule()}
              </div>
    
              <div class="questions-wrap">
                ${this.renderQuestionsCards()}
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center gap-4">
                  <ml-icon-button 
                    id="previous-question-button"
                    @btnClick="${ (e) => this.__previousQuestionClicked() }">
                    <i class="icon-1 ml-icon-flecha_desplegar text-white rotate-90"></i>
                  </ml-icon-button>

                  <ml-button-v2 
                    @btnClick="${ (e) => this.__actionClicked(QU_PREVIEW_ACTIONS.APPROVE) }" 
                    color="warning"> 
                    aprobar 
                  </ml-button-v2>

                  <ml-button-v2 
                    @btnClick="${ (e) => this.__actionClicked(QU_PREVIEW_ACTIONS.DELETE) }" 
                    color="accent"> 
                    eliminar 
                  </ml-button-v2>
                </div>
    
                <div class="flex items-center gap-4">
                  <ml-paginate-selector label="pregunta"> </ml-paginate-selector>

                  <ml-icon-button 
                    id="next-question-button"
                    @btnClick="${ (e) => this.__nextQuestionClicked() }">
                    <i class="icon-1 ml-icon-flecha_desplegar text-white -rotate-90"></i>
                  </ml-icon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, QuestionsPreviewModalComponent);
