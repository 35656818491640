export class QuickEditionQuestionServices {

  static openModal(questions, currentQuestionId){
    const previewModal = document.querySelector('ml-quick-edition-question-modal');
    questions = questions.map(_question => ({
      qid: _question.qid,
      eid: _question.eid,
      dif: _question.dif,
      sts: _question.sts,
      qu: _question.qu,
      ibc: _question.ibc,
      iid: _question.iid,
      kbid: _question.kbid,
      typ: [],
      isModify: false,
      ans: _question.ans.map(_answer => ({
        aid: _answer.answer_id,
        cnt: _answer.content,
        ro: _answer.right_one,
        mbl: _answer.must_be_last,
        isModify: false,
      }))
    }))
    if (previewModal) previewModal.open(questions, +currentQuestionId);
  }
}
