import { html } from 'lit-html';
import styles from './question-editing-card.styles.scss';
import WebComponent from 'utils/web-component';
import { STATE } from 'utils/const';
import "components/atom/badge/badge.component";
import "components/atom/text-textarea/text-textarea.component";
import "components/atom/text-hover/text-hover.component";
import "components/atom/common-textarea/common-textarea.component";
import "components/atom/radio-button/radio-button.component";
import "components/atom/checkbox/checkbox.component";
import authReducer from 'storage/reducers/auth';

const ComponentTagName = "ml-question-editing-card";
export const QUESTION_CHANGE_TYPE = {
  QUESTION: 'question',
  ANSWER: 'answer',
  RIGHT_ANSWER: 'right-answer',
  MUST_BE_LAST: 'must-be-last',
  NAVIGATION: 'navigation',
}

class QuestionEditingCardComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__user = authReducer().user;
  }

  onInit() { }

  onDestroy() { }

  __questionContentHandler(e) {
    let question = this.question;
    question.isModify = true;
    question.qu = e.detail;

    if (e.detail.length > 0) {
      e.target.setError("");
    } else {
      e.target.setError("La pregunta no puede estar vacía");
    }

    this.dispatchEvent(new CustomEvent('questionUpdateChange', { 
      detail: {
        type: QUESTION_CHANGE_TYPE.QUESTION,
        question: { qu: e.detail, qid: question.qid }
      },
      bubbles: true,
      composed: true,
    }));
  }

  __questionDifficultyHandler(e, difficulty) {
    let question = this.question;
    question.isModify = true;
    question.dif = difficulty;

    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.QUESTION,
        question: { dif: difficulty, qid: question.qid }
      },
      bubbles: true,
      composed: true,
    }));
  }

  __questionTypeHandler(e, tqid) {
    let question = this.question;
    question.isModify = true;

    if (e.detail) {
      question.typ.push(tqid);
    } else {
      question.typ = question.typ.filter((type) => type !== tqid);
    }

    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.QUESTION,
        question: { typ: question.typ, qid: question.qid }
      },
      bubbles: true,
      composed: true,
    }));
  }

  __answerRightHandler(e, aid) {
    let question = this.question;
    question.isModify = true;
    question.ans.forEach((answer) => {
      answer.ro = answer.aid === aid;
      answer.isModify = true;
    })

    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.RIGHT_ANSWER,
        aid
      },
      bubbles: true,
      composed: true,
    }));
  }

  __answerLastHandler(e, aid) {
    let question = this.question;
    question.isModify = true;
    question.ans.forEach((answer) => {
      answer.mbl = answer.aid === aid;
      answer.isModify = true;
    })

    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.MUST_BE_LAST,
        aid
      },
      bubbles: true,
      composed: true,
    }));
  }

  __answerContentHandler(e, aid) {
    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.ANSWER,
        answer: { cnt: e.detail, aid }
      },
      bubbles: true,
      composed: true,
    }));
  }

  __gotoToQuestionEdition(event){
    let { qid, kbid, iid } = this.question;

    this.dispatchEvent(new CustomEvent('questionUpdateChange', {
      detail: {
        type: QUESTION_CHANGE_TYPE.NAVIGATION,
        navigation: `/recursos?from=questionList&kbid=${kbid}&iid=${iid}&qid=${qid}`
      },
      bubbles: true,
      composed: true,
    }));
  }

  static get observedAttributes() {
    return ['index', 'question', 'types'];
  }

  get index() { return this.getAttribute('index'); }
  set index(value) { this.setAttribute('index', value); }

  get question() { 
    const serializedValue = this.getAttribute('question');
    return JSON.parse(serializedValue);
  }

  set question(value) { 
    if(typeof value == 'string') return;
    const serializedValue = JSON.stringify(value);
    this.setAttribute('question', serializedValue);
  }

  get types(){
    const serializedValue = this.getAttribute('types');
    return JSON.parse(serializedValue);
  }

  set types(value){
    if(typeof value == 'string') return;
    const serializedValue = JSON.stringify(value);
    this.setAttribute('types', serializedValue);
  }

  __getExplanation(){
    if (!Boolean(this.question)) return html``;
    let breadcrumbArray = this.question.ibc.split("||");
    let explanation = breadcrumbArray.pop().split("§")[1];

    const breadcrumbs = breadcrumbArray.map((breadcrumb, index) => {
      breadcrumb = breadcrumb.split("§")[1];
      const breadcrumbClass = index === breadcrumbArray.length - 1 ? 'semibold' : '';
      const hideImg = index === breadcrumbArray.length - 1 ? 'hidden' : '';
      return /* template */html`
        <div class="breadcrumb-item">
          <ml-text-hover text="${breadcrumb}" font="${breadcrumbClass}" position="bottom">
          </ml-text-hover>
        </div>
        <img src="assets/icons/b/flecha_desplegar.svg" class="breadcrumb-img ${hideImg}" width="20" alt="arrow-icon"/>
      `;
    });

    return { breadcrumbs, explanation }
  }

  __getStateData(){
    if (!Boolean(this.question)) return { name: '', color: 'primary' };

    let states = {};
    states[STATE.DRAFT] = { name: 'borrador', color: 'secondary' };
    states[STATE.REVISION] = { name: 'aprobado', color: 'warning' };
    states[STATE.STAGING] = { name: 'aprobado', color: 'warning' };
    states[STATE.PUBLISHED] = { name: 'publicado', color: 'success' };

    return states[this.question.sts];
  }

  __renderAnswers(){
    if(!Boolean(this.question)) return html``;
    const answers = this.question.ans.sort(
      (a, b) => (a.must_be_last === b.must_be_last) ? 0 : a.must_be_last ? 1 : -1
    );

    return answers.map((answer, index, list) => {
      const { aid, cnt, ro, mbl } = answer;
      const isLast = index == list.length - 1;

      let answerActions = html`
          <ml-radio-button
            @radioButtonToggle="${(e) => this.__answerRightHandler(e, aid)}"
            text="correcta"
            checked="${ro}" 
            name="answer-right" 
            class="px-2">
          </ml-radio-button>
        `

      if (isLast) answerActions = html`
          <div class="flex items-center justify-between px-2">
            <ml-radio-button 
              @radioButtonToggle="${(e) => this.__answerRightHandler(e, aid)}"
              text="correcta" 
              checked="${ro}" 
              name="answer-right">
            </ml-radio-button>
            <ml-checkbox 
              @checkboxToggle="${(e) => this.__answerLastHandler(e, aid)}"
              checked="${mbl}"
              text="Mostrar como ultima opción">
            </ml-checkbox>
          </div>
        `

      return html`
          <div class="answer-wrap">
            ${answerActions}
            <ml-common-textarea 
              id="${aid}"
              @inputChange="${(e) => this.__answerContentHandler(e, aid)}"
              value="${cnt}"
              color="${ro ? 'success' : 'primary'}" 
              rows="4" 
              placeholder="-">
            </ml-common-textarea>
          </div>
        `
    });
  }

  __renderHeader(){
    const stateData = this.__getStateData();
    const questionModify = this.question?.isModify ?? false;
    const isModify = questionModify || this.question?.ans.some((answer) => answer.isModify);

    return html`
    <div class="flex items-center justify-between mb-2 px-5">
      <div class="flex items-center gap-2">
        <ml-badge color="dark">
          ${ this.question?.eid == -666 ? 'IA' : 'manual'}
        </ml-badge>
        <ml-badge color="${stateData?.color ?? 'primary'}"> 
          ${stateData?.name ?? ''} 
        </ml-badge>
        <span class="paragraph-10 font-bold text-accent ${ isModify ? '' : 'hidden' }">
          pendiente de guardar cambios
        </span>
      </div>
      <div
        @click="${ (e) => this.__gotoToQuestionEdition(e) }"
        class="paragraph-10 simple-link">
        ir a adición avanzada
      </div>
    </div>`
  }

  __renderQuestionDifficulty() {
    if (!Boolean(this.question)) return html``;

    return html`
      <div class="flex items-center gap-3 justify-start p-2">
        <ml-radio-button 
          @radioButtonToggle="${(e) => this.__questionDifficultyHandler(e, 'l')}"
          text="Baja" 
          checked="${this.question.dif == 'l'}"
          name="question-difficulty">
        </ml-radio-button>

        <ml-radio-button 
          @radioButtonToggle="${(e) => this.__questionDifficultyHandler(e, 'm')}"
          text="Media" 
          checked="${this.question.dif == 'm'}" 
          name="question-difficulty">
        </ml-radio-button>

        <ml-radio-button 
          @radioButtonToggle="${(e) => this.__questionDifficultyHandler(e, 'h')}"
          text="Alta" 
          checked="${this.question.dif == 'h'}"
          name="question-difficulty">
        </ml-radio-button>
      </div>
    `
  }

  __renderQuestionTypes() {
    if (!Boolean(this.types)) return html``;
    const questionTypes = this.types ?? [];

    // checked = "${this.question.typ.includes(tqid)}" >
    return questionTypes.map((questionType) => {
      const { tqid, tnm } = questionType;
      return html`
        <ml-checkbox 
          @checkboxToggle="${(e) => this.__questionTypeHandler(e, tqid)}"
          text="${tnm}" 
          checked="false">
        </ml-checkbox>
      `
    });
  }

  template() {
    const explanationData = this.__getExplanation();

    return /* template */html`
      <div class="ml-question-card">
        ${ this.__renderHeader() }

        <div class="line mb-3"></div>

        <div class="form-wrap">
          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Pregunta: </h5>
            <ml-common-textarea
              value="${ this.question?.qu}"
              @inputChange="${(e) => this.__questionContentHandler(e) }"
              color="primary" 
              rows="4">
            </ml-common-textarea>
          </div>

          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Respuestas: </h5>
            <div class="answers-wrap">
              ${this.__renderAnswers()}
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Dificultad: </h5>
            ${ this.__renderQuestionDifficulty() }
          </div>
          
          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Tipo de pregunta: </h5>
            <div class="flex items-center gap-3 justify-start p-2">
              ${ this.__renderQuestionTypes()}
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Miga de pan: </h5>
            <div class="flex items-center gap-y-1 flex-wrap">
              ${explanationData.breadcrumbs}
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <h5 class="m-0 paragraph-14 font-bold"> Explicación: </h5>
            <p class="question-common-text mt-0"> 
              ${explanationData.explanation}
            </p>
          </div>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, QuestionEditingCardComponent);
