import { html } from 'lit-html';
import styles from './toast.styles.scss';
import WebComponent from 'utils/web-component';
import { TOAST_TYPES, ToastService } from './toast.service';
import { IaQuestionApi, QuestionsApi } from 'api_connection';
import { LayoutService } from 'components/templates/layout/layout.service';
import { QuickEditionQuestionServices } from '../quick-edition-question/quick-edition-question.service';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

const ComponentTagName = "ml-toast";

class ToastComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__messages = []; // { id: number, type: TOAST_TYPES, message: string, data: any }
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return [];
  }

  showToast(setting = {}){
    const { type, message, time, data } = setting;

    if(!type || !message) return console.error("ToastComponent: showToast() - type and message are required");

    const uniqueId = Date.now();
    this.__messages = [{ id: uniqueId, type, message, data: data ?? {} }, ...this.__messages];

    const timeToShow = time ?? 5;
    setTimeout(() => {
      const currentSize = this.__messages.length;
      this.__messages = this.__messages.filter(item => item.id != uniqueId);
      if (currentSize != this.__messages.length) this.render();
    }, timeToShow * 1000);

    this.render();
  }

  __removeMessage(id){
    this.__messages = this.__messages.filter(item => item.id != id);
    this.render();
  }

  __seeIaNotification(message){
    const { questions: qids, workHash } = message.data;
    this.__removeMessage(message.id);

    if (!(qids && qids.length))
      return this.__showNotificationError("No se encontraron preguntas", workHash);

    QuestionsApi.read.getQuestionsData({ qidl: qids }, (questions) => {
      if (questions.length == 0)
        return this.__showNotificationError("No se encontraron preguntas", workHash);

      QuickEditionQuestionServices.openModal(questions);
      IaQuestionApi.write.setWorkerAsSeen({ itw: workHash }, () => {
        LayoutService.updateNotifications();
      });
    });
  }

  __showNotificationError(message, workHash) {
    const toastData = { type: TOAST_TYPES.WARNING, message, time: 5 };
    ToastService.showToast(toastData);
    IaQuestionApi.write.setWorkerAsSeen({ itw: workHash }, () => {
      this.__notifications = this.__notifications.filter(item => item.data.workHash != workHash);
      this.render();
    });
  }

  __renderMessages(){
    const messages = this.__messages ?? [];

    return messages.map(message => {
      let notificationItem = html`
        <div class="ml-toast-message show">
          <p class="m-0 paragraph-10">
            ${message.message}
          </p>
        </div>
      `
      switch(message.type){
        case TOAST_TYPES.SUCCESS:
          notificationItem = html`<div class="ml-toast-message success show">
            <p class="m-0 paragraph-10">
              ${ unsafeHTML(message.message) }
            </p>
          </div>`
          break;
        case TOAST_TYPES.ERROR:
          notificationItem = html`<div class="ml-toast-message error show">
            <p class="m-0 paragraph-10">
              ${ unsafeHTML(message.message) }
            </p>
          </div>`
          break;
        case TOAST_TYPES.WARNING:
          notificationItem = html`<div class="ml-toast-message warning show">
            <p class="m-0 paragraph-10">
              ${ unsafeHTML(message.message) }
            </p>
          </div>`
          break;
        case TOAST_TYPES.INFO:
          notificationItem = html`<div class="ml-toast-message info show">
            <p class="m-0 paragraph-10">
              ${ unsafeHTML(message.message) }
            </p>
          </div>`
          break;
        case TOAST_TYPES.IA: {
          const { iil, nm, qty } = message.data;
          const isFullKb = !Boolean(iil);

          let notText = `${qty} pregunta(s) por párrafo generada(s) de toda la base de conocimiento.`

          if (iil && !isFullKb) {
            const paragraphsQuantity = iil.iids.length;
            notText = `${paragraphsQuantity * qty} pregunta(s) generada(s) de ${paragraphsQuantity} párrafo(s).`
          }

          const notificationText = html`<div class="flex flex-col gap-1 justify-center">
            <p class="m-0 paragraph-10"> ${notText} </p>
            <p class="m-0 paragraph-10 font-semibold">${nm}</p>
          </div>`;


          notificationItem = html`<div class="ml-toast-message info show">
            ${ notificationText }
            <img 
              @click=${ () => this.__seeIaNotification(message) }
              class="cursor-pointer"
              src="assets/icons/b/lapiz.svg" 
              width="30" 
              alt="notification-ver" />
            <img 
              @click=${ () => this.__removeMessage(message.id) }
              class="cursor-pointer"
              src="assets/icons/notifications/mal.svg" 
              width="30" 
              alt="notification-cerrar" />
          </div>`
          break;
        }
        case TOAST_TYPES.IA_ERROR: {
          const { iil, nm, qty, message } = message.data;
          const isFullKb = !Boolean(iil);

          let notText = `${qty} pregunta(s) por párrafo generada(s) de toda la base de conocimiento.`

          if (iil && !isFullKb) {
            const paragraphsQuantity = iil.iids.length;
            notText = `${paragraphsQuantity * qty} pregunta(s) generada(s) de ${paragraphsQuantity} párrafo(s).`
          }

          const notificationText = html`<div class="flex flex-col gap-1 justify-center">
            <p class="m-0 paragraph-10 font-semibold"> ${message?.error_type} : ${message?.error_message} </p>
            <p class="m-0 paragraph-10"> ${notText} </p>
            <p class="m-0 paragraph-10">${nm}</p>
          </div>`;

          notificationItem = html`<div class="ml-toast-message error show">
            <div class="icon-wrap">
              <img src="assets/icons/notifications/mal.svg" width="20" alt="notification-error" />
            </div>
            ${notificationText}
          </div>`
          break;
        }
      }

      return notificationItem;
    });
  }

  template() {
    const messages = this.__messages ?? [];
    const show = messages.length > 0;

    if (show){
      return /* template */html`
        <div class="ml-toast">
          ${ this.__renderMessages() }
        </div>
        `;
    }else{
      return html`<div class="hidden"></div>`;
    }
  }
}

window.customElements.define(ComponentTagName, ToastComponent);
