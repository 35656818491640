import { html } from 'lit-html';
import styles from './radio-button.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-radio-button";

class RadioButtonComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return ['color', 'text', 'checked', 'name', 'size'];
  }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  get text() { return this.getAttribute('text'); }
  set text(value) { this.setAttribute('text', value); }

  get checked() { 
    const checked = this.getAttribute('checked'); 
    return checked === 'true' || checked === true;
  }
  set checked(value) { 
    const checked = value === 'true' || value === true;
    this.setAttribute('checked', checked);
  }

  get name() { return this.getAttribute('name'); }
  set name(value) { this.setAttribute('name', value); }

  get size() { return this.getAttribute('size'); }
  set size(value) { 
    const size = ['sm', 'md', 'lg'].includes(value) ? value : 'md';
    this.setAttribute('size', size); 
  }

  __clickHandler(){
    if(this.checked == true || this.checked == 'true') return;
    this.clearAllRadioButtons(document);
    this.checked = !this.checked;
    this.dispatchEvent(new CustomEvent('radioButtonToggle', { 
      detail: { 
        element: this,
        checked: this.checked,
      },
      bubbles: true,
      composed: true,
    }));
    this.render();
  }

  clearAllRadioButtons(container){
    const name = this.name ?? "default";
    const radioButtons = container.querySelectorAll(`ml-radio-button[name="${name}"]`);
    radioButtons.forEach(radio => radio.checked = false);
  }

  template() {
    const size = this.size ?? 'md';
    const color = this.color ?? 'primary';
    const text = this.text ?? '';
    const checked = this.checked === 'true' || this.checked === true;
    const iconClass = checked ? "checked" : "";

    return /* template */html`
      <div 
        @click="${(e) => this.__clickHandler()}" 
        class="ml-radio-button ${color} ${iconClass} ${size}">
        <span class="icon-wrap"></span>
        <span class="text">${text}</span>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, RadioButtonComponent);
