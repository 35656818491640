import { html } from 'lit-html';
import styles from './quick-edition-question.styles.scss';
import WebComponent from 'utils/web-component';
import "components/atom/selector/selector.component";
import "components/atom/button/button.component";
import "components/atom/icon-button/icon-button.component";
import "components/atom/paginate-selector/paginate-selector.component";
import "components/molecules/question-editing-card/question-editing-card.component";
import "components/atom/confirmation-msg-modal/confirmation-msg.component";
import { STATE } from 'utils/const';
import { QuestionsApi } from 'api_connection';
import authReducer from 'storage/reducers/auth';
import { QUESTION_CHANGE_TYPE } from 'components/molecules/question-editing-card/question-editing-card.component';
import { navigateTo } from 'routing';

const ComponentTagName = "ml-quick-edition-question-modal";

export const QUICK_EDITION_ACTIONS = {
  APPROVE: 'approve',
  PUBLISH: 'publish',
  DELETE: 'delete',
  CONFIRM_DELETE: 'confirm_delete',
  SAVE: 'save',
}

class QuickEditionQuestionModalComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__user = authReducer().user;
    this.__questionTypes = [];
    this.__blockButtons = false;
    this.handlePaginateSelectorEvent = this.handlePaginateSelectorEvent.bind(this);
  }

  async onInit() {
    this.__questions = [];
    this.__currentQuestionIndex = 0;
    document.addEventListener('paginate-selector-item-clicked', this.handlePaginateSelectorEvent);

    const resp = await QuestionsApi.read.getQsTypeList({ pid: this.__user.pid });
    this.__questionTypes = resp.getQsTypeList;

    this.render();
  }

  onDestroy() {
    document.removeEventListener('paginate-selector-item-clicked', this.handlePaginateSelectorEvent);
  }

  afterCallback() {
    const questionCard = this.root.querySelector(`ml-question-editing-card[index="${this.__currentQuestionIndex}"]`);
    if (questionCard) questionCard.scrollIntoView({ behavior: 'smooth' });
  }

  handlePaginateSelectorEvent(event) {
    this.__currentQuestionIndex = +event.detail.index;

    const questionCard = this.root.querySelector(`ml-question-editing-card[index="${this.__currentQuestionIndex}"]`);
    if (questionCard) questionCard.scrollIntoView({ behavior: 'smooth' });
    this.render();
  }

  __renderQuestionChanges(event) {
    const { type, question, aid, answer, navigation } = event.detail;
    let currentQuestion = this.__questions[this.__currentQuestionIndex];

    if (type === QUESTION_CHANGE_TYPE.QUESTION){
      this.__questions[this.__currentQuestionIndex] = {
        ...currentQuestion,
        ...question,
        isModify: true
      }
    }

    if(type === QUESTION_CHANGE_TYPE.RIGHT_ANSWER){
      this.__questions[this.__currentQuestionIndex] = {
        ...currentQuestion,
        ans: currentQuestion.ans.map((answer) => {
          return {
            ...answer,
            ro: answer.aid === aid,
            isModify: true,
          }
        })
      }
    }

    if(type === QUESTION_CHANGE_TYPE.MUST_BE_LAST){
      this.__questions[this.__currentQuestionIndex] = {
        ...currentQuestion,
        ans: currentQuestion.ans.map((answer) => {
          return {
            ...answer,
            mbl: answer.aid === aid,
            isModify: true,
          }
        })
      }
    }

    if(type === QUESTION_CHANGE_TYPE.ANSWER){
      this.__questions[this.__currentQuestionIndex] = {
        ...currentQuestion,
        ans: currentQuestion.ans.map((ans) => {
          if(ans.aid === answer.aid) return { ...ans, ...answer, isModify: true };
          return ans;
        })
      }
    }

    if(type === QUESTION_CHANGE_TYPE.NAVIGATION){
      this.close();
      navigateTo(navigation);
    }else{
      this.render();
    }
  }

  static get observedAttributes() {
    return [];
  }

  close() {
    const background = this.root.querySelector('.ml-modal-background');
    const body = this.root.querySelector('.ml-modal-body');
    body.classList.remove('show');
    setTimeout(() => {
      background.classList.add('hidden');
      this.__questions = [];
      this.__questionTypes = [];
      this.dispatchEvent(new CustomEvent('close-question-preview-modal'));
      this.render();
    }, 400);
  }

  open(questions, questionId) {
    const background = this.root.querySelector('.ml-modal-background');
    const body = this.root.querySelector('.ml-modal-body');
    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    background.classList.remove('hidden');
    this.__questions = questions;

    if (questionId) {
      const index = this.__questions.findIndex(question => question.qid == questionId);
      if (index >= 0) this.__currentQuestionIndex = index;
      questions.forEach((item, i) => item.active = i == index);
    } else {
      this.__currentQuestionIndex = 0;
      questions[0].active = true;
    }

    if (paginateSelector) paginateSelector.loadList(questions);
    setTimeout(() => {
      body.classList.add('show');
    }, 100);
    this.render();
  }

  __nextQuestionClicked() {
    this.__currentQuestionIndex += 1;

    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    const nextQuestionCard = this.root.querySelector(`ml-question-editing-card[index="${this.__currentQuestionIndex}"]`);

    if (nextQuestionCard) {
      nextQuestionCard.scrollIntoView({ behavior: 'smooth' });
      if (paginateSelector) paginateSelector.updateIndex(this.__currentQuestionIndex);
    } else {
      this.__currentQuestionIndex -= 1;
    }
    this.render();
  }

  __previousQuestionClicked() {
    this.__currentQuestionIndex -= 1;

    const paginateSelector = this.root.querySelector('ml-paginate-selector');
    const previousQuestionCard = this.root.querySelector(`ml-question-editing-card[index="${this.__currentQuestionIndex}"]`);

    if (previousQuestionCard) {
      previousQuestionCard.scrollIntoView({ behavior: 'smooth' });
      if (paginateSelector) paginateSelector.updateIndex(this.__currentQuestionIndex);
    } else {
      this.__currentQuestionIndex += 1;
    }
    this.render();
  }

  async __saveChanges() {
    let currentQuestion = this.__questions[this.__currentQuestionIndex];
    const answers = currentQuestion.ans.filter((answer) => answer.isModify);

    if(currentQuestion.isModify){
      const request = {
        pid: this.__user.pid,
        qid: currentQuestion.qid,
        qu: currentQuestion.qu,
        dif: currentQuestion.dif,
        // TODO: Add typ
      }
      await QuestionsApi.write.updateQuestion(request);
      this.__questions[this.__currentQuestionIndex].isModify = false;
      if(answers.length == 0) this.render();
    }

    if(answers.length > 0){
      answers.forEach(async(answer) => {
        const request = {
          pid: this.__user.pid,
          aid: answer.aid,
          cnt: answer.cnt,
          ro: answer.ro,
          mbl: answer.mbl,
        }
        await QuestionsApi.write.updateAnswer(request);
      });

      this.__questions[this.__currentQuestionIndex] = {
        ...currentQuestion,
        ans: currentQuestion.ans.map((ans) => ({ ...ans, isModify: false }))
      }
    }
  }

  async __actionClicked(actionId) {
    let currentQuestion = this.__questions[this.__currentQuestionIndex];
    this.__blockButtons = true;

    switch(actionId){
      case QUICK_EDITION_ACTIONS.SAVE: 
        await this.__saveChanges();
        this.__blockButtons = false;
        this.render();
        break;

      case QUICK_EDITION_ACTIONS.DELETE:
        const mainContainer = this.root.querySelector('.main-container');
        const confirmationElement = this.root.querySelector('preview-confirmation-msg');

        let content = {
          title: "¿Estás seguro? Esta acción no tiene vuelta atrás",
          okText: "Sí, eliminar",
          htmlContent: `
        <p> La pregunta pasará a el estado "deprecate", </p>
        <p> lista para ser eliminada definitivamente. </p>
      `,
        }

        if (confirmationElement) confirmationElement.loadData({
          title: content.title,
          htmlContent: content.htmlContent,
          inputText: actionId == QUICK_EDITION_ACTIONS.DELETE ? "ELIMINAR" : "",
          okText: content.okText,
          onOk: () => {
            mainContainer.classList.toggle('show-confirmation', false);
            this.__actionClicked(QUICK_EDITION_ACTIONS.CONFIRM_DELETE);
          },
          onCancel: () => {
            mainContainer.classList.toggle('show-confirmation', false);
            this.__blockButtons = false;
            this.render();
          }
        });

        if (mainContainer) mainContainer.classList.toggle('show-confirmation', true);
        break;
      
      case QUICK_EDITION_ACTIONS.CONFIRM_DELETE:
        const paginateSelector = this.root.querySelector('ml-paginate-selector');
        QuestionsApi.write.deleteQuestion({ pid: this.__user.pid, qidl: [currentQuestion.qid] });

        this.__questions = this.__questions.filter(question => question.qid !== currentQuestion.qid);
        if (this.__questions.length == 0) return this.close();

        this.__currentQuestionIndex -= 1;
        if (this.__currentQuestionIndex < 0) this.__currentQuestionIndex = 0;
        if (paginateSelector) paginateSelector.loadList(this.__questions.map((item, i) => ({
          ...item,
          active: i == this.__currentQuestionIndex
        })));

        if (currentQuestion.sts == STATE.DRAFT) this.__totalDraft -= 1;
        this.__blockButtons = false;
        this.render();
        break;

      case QUESTION_CHANGE_TYPE.APPROVE:{
        const questionModify = currentQuestion?.isModify ?? false;
        const isModify = questionModify || currentQuestion?.ans.some((answer) => answer.isModify);
        if(isModify) await this.__saveChanges();

        await QuestionsApi.write.revisionQuestion({ pid: this.__user.pid, qidl: [currentQuestion.qid] });
        this.__questions[this.__currentQuestionIndex].sts = STATE.REVISION;
        this.__blockButtons = false;
        this.render();
        break;
      }

      case QUICK_EDITION_ACTIONS.PUBLISH:{
        const questionModify = currentQuestion?.isModify ?? false;
        const isModify = questionModify || currentQuestion?.ans.some((answer) => answer.isModify);
        if (isModify) await this.__saveChanges();

        await QuestionsApi.write.readyQuestion({ pid: this.__user.pid, qidl: [currentQuestion.qid] });
        this.__questions[this.__currentQuestionIndex].sts = STATE.PUBLISHED;
        this.__blockButtons = false;
        this.render();
        break;
      }
    }
  }

  renderQuestionsCards() {
    this.__questions ??= [];
    if (this.__questions.length === 0) return html``;
    return this.__questions.map((question, index) => {
      return html`
        <ml-question-editing-card 
          @questionUpdateChange="${(event) => this.__renderQuestionChanges(event)}"
          .question="${question}" 
          .types="${this.__questionTypes}"
          index="${index}" 
          class="question-card-width">
        </ml-question-editing-card>
      `
    });
  }

  template() {
    const questions = this.__questions ?? [];
    const currentQuestion = questions[this.__currentQuestionIndex];
    const questionModify = currentQuestion?.isModify ?? false;
    const isModify = questionModify || currentQuestion?.ans.some((answer) => answer.isModify);
    const isIndexLower = this.__currentQuestionIndex <= 0;
    const isIndexHigher = this.__currentQuestionIndex >= questions.length - 1;

    return /* template */html`
      <div class="ml-modal-background hidden">
        <div class="ml-modal-body">
          <i @click="${(e) => this.close()}" class="ml_close"></i>
          <div class="flex justify-center items-center gap-2">
            <h5 class="title font-bold m-0"> Edición rápida </h5>
          </div>

          <div class="main-container">
            <div class="confirmation-wrap">
              <preview-confirmation-msg></preview-confirmation-msg>
            </div>

            <div class="content">
              <div class="questions-wrap">
                ${this.renderQuestionsCards()}
              </div>

              <div class="flex items-end justify-between">
                <div class="flex items-end gap-4">
                  <ml-icon-button 
                    mode="${ isIndexLower ? 'disabled' : 'normal' }"
                    @btnClick="${(e) => this.__previousQuestionClicked()}">
                    <i class="icon-1 ml-icon-flecha_desplegar text-white rotate-90"></i>
                  </ml-icon-button>

                  <div class="flex flex-col gap-1">
                    <div class="text-14 font-semibold">Enviar a:</div>
                    <div class="flex items-center gap-4">
                      <ml-button-v2
                        class="${ currentQuestion?.sts == STATE.PUBLISHED ? 'hidden' : '' }"
                        @btnClick="${(e) => this.__actionClicked(QUICK_EDITION_ACTIONS.PUBLISH)}" 
                        mode="${ this.__blockButtons ? 'disabled' : 'normal' }"
                        color="success"> 
                        publicar
                      </ml-button-v2>
    
                      <ml-button-v2 
                        class="${ currentQuestion?.sts == STATE.REVISION ? 'hidden' : '' }"
                        @btnClick="${(e) => this.__actionClicked(QUICK_EDITION_ACTIONS.APPROVE)}" 
                        mode="${ this.__blockButtons ? 'disabled' : 'normal' }"
                        color="warning"> 
                        aprobar 
                      </ml-button-v2>
    
                      <ml-button-v2 
                        @btnClick="${(e) => this.__actionClicked(QUICK_EDITION_ACTIONS.DELETE)}" 
                        mode="${ this.__blockButtons ? 'disabled' : 'normal' }"
                        color="accent"> 
                        eliminar 
                      </ml-button-v2>

                      <ml-button-v2
                        @btnClick="${(e) => this.__actionClicked(QUICK_EDITION_ACTIONS.SAVE)}" 
                        mode="${ (isModify && !this.__blockButtons) ? 'normal' : 'disabled' }"
                        color="secondary"> 
                        guardar
                      </ml-button-v2>
                    </div>
                  </div>
                </div>
    
                <div class="flex items-center gap-4">
                  <ml-paginate-selector label="pregunta"> </ml-paginate-selector>

                  <ml-icon-button 
                    mode="${ isIndexHigher ? 'disabled' : 'normal' }"
                    @btnClick="${(e) => this.__nextQuestionClicked()}">
                    <i class="icon-1 ml-icon-flecha_desplegar text-white -rotate-90"></i>
                  </ml-icon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, QuickEditionQuestionModalComponent);
