import "regenerator-runtime/runtime.js";
import "./style.scss";
import 'bed-path-dot-home';
import 'components/page-loading/page-loading';
import 'components/templates/layout/layout';
import 'components/organism/questions-preview/questions-preview.component';
import 'components/molecules/quick-edition-question/quick-edition-question.component';
import "components/molecules/toast/toast.component";

import App from './App';
import {router} from 'routing';
import * as Sentry from "@sentry/browser";
import { $ } from "utils/dom-query";

if(process.env.ENV == 'production'){
  Sentry.init({
    dsn: "https://b4604877ff7941ca962165ccee8f61ae@o1184240.ingest.sentry.io/4505444381229056",
    environment: "production",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["cms.meludus.com"],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 0.2,
  });
}

document.addEventListener('DOMContentLoaded', App);

window.addEventListener("popstate", router);

window.g_loading = () => $("#main_loading");
